// import React from 'react'
// import ReactDOM from 'react-dom'
// import example from './example'

// //const app = () => <div>the time is {new Date().toLocaleTimeString()}</div>
// //ReactDOM.render(app(), document.getElementById('root'))
// // ReactDOM.render(<>{example}</>, document.getElementById('root'))

// /*
// setInterval(() => {
//   ReactDOM.render(app(), document.getElementById('root'))
// }, 1000)
// */


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Test } from './Test';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Test />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

