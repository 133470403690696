import { useEffect, useState } from "react";
import './Test.css';

function sumNumbersInText(text:string) {
    // Use a regular expression to match all numbers (including decimals)
    const numberPattern = /-?\d+(\.\d+)?/g;
    // Match the numbers in the text
    const numbers = text.match(numberPattern);
    
    if (numbers === null) {
        return 0;
    }

    // Convert the matched strings to numbers and sum them up
    const sum = numbers.reduce((acc, num) => acc + parseFloat(num), 0);
    
    return sum;
    
}

const localStorageValue:string = localStorage.getItem('tallyData') || "";


export const Test = () => {
    const [value, setValue] = useState<number>(0);

    useEffect(() => {
        // localStorage.setItem('tallyData', );
    }, [value]);
    useEffect(() => {
        setValue(sumNumbersInText(localStorageValue));
    }, []);
    
    const setLines = (textareaValue:string) => {
        setValue(sumNumbersInText(textareaValue));
        localStorage.setItem('tallyData', textareaValue);
    }
    
    return (
        <div>
            <div id="bill">{value}</div>
            <br />
            <textarea onChange={(el) => setLines(el.currentTarget.value)}>{localStorageValue}</textarea>
        </div>

    )
}